import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import OurCompanyTemplate from '../templates/our-company-template';

const copy = {
	description:
		'EK Construction is an adaptable, team-oriented general contracting firm that specialises in commercial and residential work in Simcoe, Muskoka, & the GTA.',
	masthead: {
		title: 'Our Company',
		img: '/img/our-company-masthead.jpg',
	},
	intro: {
		bgImg: '/img/ConcreteBG_light_top.jpg',
		title: 'Our Focus',
		text:
			'At the EK Construction Company, we are focused on three things. First, we look to streamline the construction process by minimising points of contact, and maximising communication within the owner-designer-constructor triangle. Second, we make the commitment to building responsibly, meaning that we prioritise the safety of our team members, conduct responsible waste disposal, and collaborate with designers and building officials to maintain modern industry standards and safeguards for best building and installation practices. Finally, we focus on providing a superior client experience by prioritising communication, professionalism, and partnership with all clients.',
		img: {
			asset: '/img/our-company-1.jpg',
			alt: 'EK Construction',
		},
	},
	success: {
		bgImg: '/img/ConcreteBG_light_bottom.jpg',
		title: 'Successful construction projects all have three things in common:',
		items: [
			'A Commitment to Quality',
			'Recognition of Collaboration',
			'Trust in the Relationship',
		],
	},
	fullWidth: {
		bgImg: '/img/cta-saw.jpg',
		text:
			'Build well, live well, and be well by building together with the <br />EK Construction Company',
	},
	leadership: {
		title: 'Our Leadership Team',
		members: [
			{
				name: 'Ean Koo',
				quals: 'B.A., B.Ed., M.Ed.',
				title: 'Founder and Principal',
				image: '/img/bio-ean.jpg',
			},
			{
				name: 'Amandeep Chohan',
				quals: '',
				title: 'General Foreman',
				image: '/img/bio-amandeep.jpg',
			},
		],
	},
	footer: {
		text: 'Let’s discuss your next commercial or residential project.',
		cta: {
			text: 'CONTACT US',
			link: '/contact/',
		},
		img: '/img/EK_Home_Bottom.jpg',
	},
};

const OurCompanyPage = () => (
	<Layout>
		<SEO title="Our Company" description={copy.description} />
		<OurCompanyTemplate copy={copy} />
	</Layout>
);

export default OurCompanyPage;
