import React from 'react';
import styled from 'styled-components';

import whiteHex from '../../../static/img/white-hex.svg';

const StyledIntro = styled.section`
	background-image: url(${whiteHex}), url(${(p) => p.img});
	background-position: -2rem 1.5rem, center top;
	background-size: 35%, contain;
	background-repeat: no-repeat;
	background-color: ${(p) => p.theme.color.snowflake};

	@media (max-width: 768px) {
		background-position: center 5rem, center top;
		background-size: contain, contain;
	}

	.intro-columns-container {
		padding: 3.25rem 0 3.25rem;

		@media (max-width: 768px) {
			padding-bottom: 0;
		}

		.text-container {
			* {
				color: inherit;
			}

			@media (min-width: 769px) {
				padding-right: 5rem;
			}
		}

		.img-container {
			max-width: 450px;
			margin: 0 auto;

			@media (min-width: 769px) {
				padding-top: 3rem;
			}
		}
	}
`;

const Intro = ({ bgImg, title, text, img }) => {
	return (
		<StyledIntro className="section" img={bgImg}>
			<div className="container">
				<div className="intro-columns-container">
					<div className="columns">
						<div className="column">
							<div className="text-container">
								<h2 dangerouslySetInnerHTML={{ __html: title }} />
								<p>{text}</p>
							</div>
						</div>
						<div className="column">
							<div className="img-container">
								<img src={img.asset} alt={img.alt} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</StyledIntro>
	);
};

export default Intro;
