import React from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
	background-color: ${(p) => p.theme.color.snowflake};
	background-image: url(${(p) => p.bg});
	background-position: center bottom;
	background-size: 100% auto;
	background-repeat: no-repeat;

	.success-columns-container {
		padding-bottom: 6.25rem;

		@media (max-width: 768px) {
			padding-bottom: 3rem;
		}

		.columns {
			/* 
      * This media query was written to ensure
      * these columns wrap for iPad portrait.
      */
			@media (max-width: 900px) {
				display: block;

				.column {
					display: block;
				}
			}
		}

		h2 {
			text-align: center;
			margin-bottom: 2.2em;
		}

		.success-item-inner {
			max-width: 312px;
			margin: 0 auto;
			padding: 2.375rem 0.5rem;
			background-color: ${(p) => p.theme.color.lightest};

			.success-item {
				font-size: 1.5625rem;
				font-weight: 600;
				line-height: 1.6;
				text-align: center;
				width: 9em;
				max-width: 100%;
				margin: 0 auto;
			}
		}
	}
`;

const Success = ({ data }) => {
	const { bgImg, title, items } = data;

	return (
		<StyledSection bg={bgImg} className="section">
			<div className="container">
				<div className="success-columns-container">
					<h2>{title}</h2>
					<div className="columns">
						{items.map((item) => (
							<div className="column" key={item}>
								<div className="success-item-inner">
									<p className="success-item">{item}</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</StyledSection>
	);
};

export default Success;
