import React from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
	background-image: url(${(p) => p.bg});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-color: ${(p) => p.theme.color.darkGrey};
	padding: 8.75rem 2rem;

	@media (max-width: 499px) {
		padding: 5rem 2rem;
	}

	h2 {
		font-size: 2.0625rem;
		font-weight: 500;
		line-height: 1.7;
		color: ${(p) => p.theme.color.lightest};
		text-align: center;
		max-width: 18em;
		margin: 0 auto;

		@media (max-width: 499px) {
			font-size: 1.5rem;
		}
	}
`;

const FullWidth = ({ bgImg, text }) => (
	<StyledSection className="secton" bg={bgImg}>
		<h2 dangerouslySetInnerHTML={{ __html: text }} />
	</StyledSection>
);

export default FullWidth;
