import React from 'react';

import Masthead from '../components/masthead';
import Intro from '../components/our-company/intro';
import Success from '../components/our-company/success';
import FullWidth from '../components/our-company/full-width';
import Leadership from '../components/our-company/leadership';
import Footer from '../components/footer-cta';

const OurCompanyTemplate = ({ copy }) => {
	const { masthead, intro, success, fullWidth, leadership, footer } = copy;
	return (
		<>
			<Masthead title={masthead.title} img={masthead.img} />
			<Intro
				bgImg={intro.bgImg}
				title={intro.title}
				text={intro.text}
				img={intro.img}
			/>
			<Success data={success} />
			<FullWidth bgImg={fullWidth.bgImg} text={fullWidth.text} />
			<Leadership title={leadership.title} members={leadership.members} />
			<Footer text={footer.text} cta={footer.cta} img={footer.img} />
		</>
	);
};

export default OurCompanyTemplate;
