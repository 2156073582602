import React from 'react';
import styled from 'styled-components';

import topBgImg from '../../../static/img/ConcreteBG_light_top.jpg';
import bottomBgImg from '../../../static/img/ConcreteBG_light_bottom.jpg';

const StyledSection = styled.section`
	background-color: ${(p) => p.theme.color.snowflake};
	background-image: url(${topBgImg}), url(${bottomBgImg});
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: center top, center bottom;

	.leadership-columns-container {
		padding: 3.25rem 0 1rem;

		h2 {
			text-align: center;
			margin-bottom: 2.2em;
		}

		.member-container {
			max-width: 330px;
			margin: 0 auto 2.5rem;

			.img-container {
				margin-bottom: 2.375rem;
			}

			.details-container {
				font-size: 1.25rem;

				h3 {
					margin-bottom: 0.5em;

					span {
						font-weight: 700;
						font-size: 1.5625rem;
					}
				}
			}
		}
	}
`;

const Leadership = ({ title, members }) => {
	return (
		<StyledSection className="section">
			<div className="container">
				<div className="leadership-columns-container">
					<h2>{title}</h2>
					<div className="columns">
						{members.map((member) => {
							const hasQuals =
								member.quals && member.quals !== '' ? true : false;

							return (
								<div className="column" key={member.name}>
									<div className="member-container">
										<div className="img-container">
											<img src={member.image} alt={member.name} />
										</div>
										<div className="details-container">
											<h3>
												<span>{member.name} </span>
												{hasQuals && member.quals}
											</h3>
											<p>{member.title}</p>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</StyledSection>
	);
};

export default Leadership;
